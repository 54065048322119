<template>
  <!-- 挂牌管理 -->
  <div class="page-card-demo">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <el-form-item label="商品类别" label-width="80px">
        <el-cascader
          ref="goodsCategoryRef"
          v-model="formInline.goodsCategoryArr"
          placeholder="请选择"
          :options="categoryOption"
          :props="categoryCascadeProps"
          clearable
          @change="categoryChange"
        />
      </el-form-item>
      <el-form-item label="规格" label-width="80px">
        <el-select v-model="formInline.goodsSpecName" size="small" placeholder="选择完商品类别才会出现型号">
          <el-option label="全部" value />
          <el-option
            v-for="(item, index) in goodsSpecOption"
            :key="index"
            :label="item.specs"
            :value="item.specs"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="材质" label-width="80px">
        <el-select v-model="formInline.goodsMaterialName" size="small" placeholder="选择完商品类别才会出现材质">
          <el-option label="全部" value />
          <el-option
            v-for="(item, index) in goodsMaterialOption"
            :key="index"
            :label="item.materials"
            :value="item.materials"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="更新日期" label-width="80px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDate"
        />
      </el-form-item>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #productMainUrl="{ row }">
          <img v-if="(row.productMainUrl.indexOf('http')>=0)" class="table-row-img" :src="row.productMainUrl" alt="">
          <img v-else class="table-row-img" :src="$fileUrl + row.productMainUrl" alt="">
        </template>
        <template #goodsOneCategoryName="{ row }">
          <div>{{ `${row.goodsOneCategoryName}/${row.goodsTwoCategoryName}` }}</div>
        </template>
        <template #goodsMaterialName="{ row }">
          <div>{{ `${row.goodsSpecName}/${row.goodsMaterialName}/${row.manufactor}` }}</div>
        </template>
        <template #goodsStatus="{ row }">
          <div>
            <p>{{ row.goodsStatus === '00' ? '待上架' : row.goodsStatus === '01' ? '已上架' : '已下架' }}</p>
            <el-tag v-if="row.goodsLabel" class="ml-2" :type="row.goodsLabel === '00' ? '' : row.goodsLabel === '01' ? 'info' : 'danger'">
              {{ row.goodsLabel === '00' ? '售罄' : row.goodsLabel === '01' ? '到期下架 ' : '手动下架' }}
            </el-tag>
          </div>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import { tradeGoodsListingPagePlat, goodsGetGoodsCategoryList, goodsGetGoodsSpecList, goodsGetGoodsMaterialList } from '@/api/shoppingMall'

export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      dateRange: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      listData: [],
      total: 0,
      loading: false,
      searchFormItemArr: [{ type: 'select', label: '状态', value: 'goodsStatus', width: '80px', pLabel: 'dictName', pValue: 'dictId', child: [{ dictId: '00', dictName: '待上架' }, { dictId: '01', dictName: '已上架' }, { dictId: '02', dictName: '已下架' }] }, { type: 'input', label: '挂牌商家', value: 'cmpName', width: '80px' }],
      itemData: [
        { label: '商品图片', prop: 'productMainUrl', width: 200 },
        { label: '挂牌商家', prop: 'cmpName', width: 220 },
        { label: '商品类别', prop: 'goodsOneCategoryName', width: 180 },
        { label: '材质/规格/钢厂', prop: 'goodsMaterialName', width: 180 },
        { label: '挂牌单号', prop: 'tradeGoodsNo', width: 180 },
        { label: '含税单价（元/吨）', prop: 'unitPriceIncludingTax', type: 'money', width: 180 },
        { label: '挂牌数量（吨）', prop: 'listingWeight', width: 120 },
        { label: '已售数量（吨）', prop: 'saleWeight', width: 120 },
        { label: '更新时间', prop: 'modifyTime', width: 150 },
        { label: '状态', prop: 'goodsStatus', width: 120, child: [{ label: '待上架', id: '00' }, { label: '已上架', id: '01' }, { label: '已下架', id: '02' }] }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.editRow }
      ],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName'
      },
      goodsCategoryRef: null,
      // 商品名称
      categoryOption: [],
      // 规格
      goodsSpecOption: [],
      // 材质
      goodsMaterialOption: []
    }
  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.categoryOption = res.data
        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map((item) => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },
    // 级联change事件
    categoryChange(val) {
      if (!val || val.length === 0) {
        this.goodsSpecOption = this.goodsMaterialOption = []
        return
      }
      const goodsArr = this.$refs.goodsCategoryRef.getCheckedNodes()[0].pathLabels
      this.formInline.goodsOneCategoryName = goodsArr[1]
      this.formInline.goodsTwoCategoryName = goodsArr[2]
      this.associativeSearch(val[2])
    },
    // 材质跟型号的搜索
    associativeSearch(data) {
      if (!data) return
      goodsGetGoodsMaterialList(data, res => {
        this.goodsMaterialOption = [...res.data]
      })
      goodsGetGoodsSpecList(data, res => {
        this.goodsSpecOption = [...res.data]
      })
    },
    // 获取日期
    getDate(value) {
      if (!value) {
        this.formInline.startTime = ''
        this.formInline.endTime = ''
        return
      }
      this.formInline.startTime = value[0]
      this.formInline.endTime = value[1]
    },
    // 获取分页数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          goodsCategoryArr: []
        }
        this.dateRange = []
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      tradeGoodsListingPagePlat(this.formInline, res => {
        this.listData = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 编辑、详情
    editRow(row) {
      this.$router.push({ path: '/shoppingMall/listingManagementDeatail', query: { id: row.id, type: row.goodsStatus }})
    }
  }
}
</script>

<style scoped lang="scss">
:deep .el-tabs__nav-wrap::after {
  width: 0;
}
:deep .el-tabs__active-bar {
  display: none;
}
.table-row-img {
  width: 50px;
  height: 50px;
}
</style>
